import '../styles/contact-us-form.css';
import '../styles/menu.css';

import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';

import call1 from '../images/call-24-px.png';
import droparrow from '../images/DownArrow2.svg';
import email1 from '../images/ic-email-24-px.png';
import iconback from '../images/icon-back-black.svg';
import iconorange from '../images/icon-back-orange.svg';
import OwnerlyLogo from '../images/ownerly-logo.svg';
import phone from '../images/phone.svg';
import * as commonStyles from '../styles/head.module.css';

const HDImg = 'https://cdn.getmybubble.com/images/new-quote-flow/bubble-logo.svg';
//import MenuImg from "../images/menuicon.png";
import AboutContent from './aboutHeader';
import HomePartners from './homePartners';
import LifePartners from './lifePartners';
let maxY;

const Menu = () => {

  const [ showAbout, setAbout ] = useState( false );
  const [ showHome, setHome ] = useState( false );
  const [ showLife, setLife ] = useState( false );
  const [ active, setActive ] = useState( 0 );
  const [ showNav, toggleNav ] = useState( true );

  let innerWidth = 992;
  // const { innerWidth } = window;
  if ( typeof window !== 'undefined' ) {
    innerWidth = window.innerWidth;
  }

  const handleClickOutside = e => {
    if ( active ) {
      if ( maxY && maxY < e.pageY ) {
        setActive( 0 );
      }
    }
  };

  useEffect( () => {
    document.addEventListener( 'mousedown', handleClickOutside );
    return () => document.removeEventListener( 'mousedown', handleClickOutside );
  } );

  useEffect( () => {
    if ( innerWidth < 991 ) {
      toggleNav( false );
    }
  }, [innerWidth] );

  return (
    <nav id="menu-nav">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-12">
          <nav className={`navbar navbar-expand-md fixed-top bgFFF ${showAbout ? '' : 'border-btm-paleGray'}`}>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" style={{ color: 'black' }}></span>
            </button>

            <div className="collapse navbar-collapse menuNav menuNavUse" id="navbarCollapse">
              <div className={commonStyles.hdContainer + ' navbar-nav'}>
                <Link
                  to="/">
                  <img src={OwnerlyLogo} className={commonStyles.HDImg} id="logoRoyal" alt="Bubble Royal United Mortgage"></img>
                </Link>
              </div>

              <div className={`navbar-nav ${showNav ? '' : ''}`}>
                <div className="navBox">
                  {/* <div style={{ padding: "15px 0px" }} className={`${active === 1 ? 'orangeBtnActive' : ''}`} >
                                        <a onClick={() => { active === 1 ? setActive(0) : setActive(1); setAbout(!showAbout) }}
                                            tabIndex={0} role="button">
                                            About Bubble
                                        </a>
                                        <div className="show-sm">
                                            <img src={showAbout ? iconorange : iconback} onClick={() => { active === 1 ? setActive(0) : setActive(1); setAbout(!showAbout) }}
                                                tabIndex={0} alt="" role="button" />
                                        </div>
                                    </div> */}


                  {/* <div className="d-sm-block d-md-none">
                                        {showAbout &&
                                            <AboutContent />
                                        }
                                    </div> */}

                  <div style={{ padding: '15px 0px' }} className={`${active === 2 ? 'orangeBtnActive' : ''}`}>
                    <a onClick={() => {
                      active === 2 ? setActive( 0 ) : setActive( 2 ); setHome( !showHome ); 
                    }}
                    tabIndex={0} role="button">
                                            Home Insurance
                    </a>

                    <div className="show-sm">
                      <img src={showHome ? iconorange : iconback} alt="" onClick={() => {
                        active === 2 ? setActive( 0 ) : setActive( 2 ); setHome( !showHome ); 
                      }}
                      tabIndex={0} role="button" />
                    </div>
                  </div>

                  <div className="d-sm-block d-md-none">
                    {showHome &&
                                            <HomePartners />
                    }
                  </div>

                  <div style={{ padding: '15px 0px' }} className={`${active === 3 ? 'orangeBtnActive' : ''}`}>
                    <a onClick={() => {
                      active === 3 ? setActive( 0 ) : setActive( 3 ); setLife( !showLife ); 
                    }}
                    tabIndex={0} role="button">
                                            Life Insurance
                    </a>

                    <div className="show-sm">
                      <img src={showLife ? iconorange : iconback} alt="" onClick={() => {
                        active === 3 ? setActive( 0 ) : setActive( 3 ); setLife( !showLife ); 
                      }}
                      tabIndex={0} role="button" />
                    </div>
                  </div>

                  <div className="d-sm-block d-md-none">
                    {showLife &&
                                            <LifePartners />
                    }
                  </div>

                </div>

                {/*
                                <div className="section10 d-md-none1111 " data-bs-toggle="modal" data-bs-target="#aaaa">
                                    <a > <span style={{ width: "72px" }}><img src={email} className="phone-img-align"></img> </span>
                                        <span className="sh-mob">Form  </span>
                                    </a>
                                </div> */}

                {/* <div class="modal fade" id="aaaa" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">

                                                <HubSpotContactUsForm />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}


                <div className="dropdown1 d-md-none1111 section30">
                  <div className="menu-with-arrow"> <span className="sh-brw" style={{ width: '72px' }}> <img src={phone} alt='arrow' className="phone-img-align dropbtn"></img></span><div className="sh-mob" data-bs-toggle="collapse" data-href="#collapseExample99" role="button" aria-expanded="false" aria-controls="collapseExample"><span className="sh-mob1"> Contact Us

                    <img src={droparrow} alt="arrow"></img></span> </div>

                  </div>

                  <div className="dropdown-content aboutPage">
                    <div className=" flexRow justify-content-between makeColumn">
                      <div id="collapseExample99" >
                        <div className="flexColumn touch">
                          <div className="title">
                                                        Get in touch with us
                          </div>

                          <div className="flexRow">
                            <img src={call1} alt="call" />

                            <div className="makeColumn">
                              <span className="learnMore fwNormal"> (833) 900-BUBB</span>

                              {/* <p className="para"> (Monday-Sunday, 8AM-8PM ET) </p> */}
                            </div>
                          </div>

                          <div className="flexRow">
                            <img src={email1} alt="email" />

                            <div className="makeColumn">
                              <a href="mailto:hello@getmybubble.com" target="_blank" rel="noopener noreferrer">
                                <span className="learnMore fwNormal"> hello@getmybubble.com </span>
                              </a>

                              <p className="para" style={{ opacity: 1 }}>
                                                                (24/7, at your service)
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                {/*
        <div class="dropdown1 d-md-none1">
            <a style={{ width: "72px" }}><img src={user} className="phone-img-align dropbtn"></img></a>
            <div class="dropdown-content user1">
                <div class=" flexRow justify-content-between makeColumn">
                    <div className="flexColumn touch">
                        <div className="flexRow">
                            <img src={person} alt="person" />
                            <div className="makeColumn">
                                <span className="learnMore fwNormal">
                                Login to your account
                            </span>
                            </div>
                        </div>
                        <div className="flexRow">
                            <img src={gps} alt="gps" />
                            <div className="makeColumn">
                                <span className="learnMore fwNormal">
                                Track your application
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}

                {/* <div class="dropdown1 d-md-none1">
        <div class="dropbtn"> */}

                <button type="button" style={{ marginTop: '10px' }} className={commonStyles.hdLoginBttn}>Get a Quote</button>

                {/* </div>
        <div class="dropdown-content GetQuote">
            <div class=" flexRow justify-content-between makeColumn">
                <div className="flexColumn touch">
                    <div className="flexRow">
                        <div className="makeColumn">
                            <span className="learnMore fwNormal">
                            Home  & Life Insurance
                        </span>
                        </div>
                    </div>
                    <div className="flexRow">
                        <div className="makeColumn">
                            <span className="learnMore fwNormal">
                            Home Insurance
                        </span>
                        </div>
                    </div>

                    <div className="flexRow">
                        <div className="makeColumn">
                            <span className="learnMore fwNormal">
                            Life Insurance
                        </span>
                        </div>
                    </div>

                </div>
            </div>
        </div>


    </div> */}
              </div>


            </div>
          </nav>
        </div>
      </div>

      <div className={commonStyles.hdMianContainermob}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
              <nav className="navbar navbar-expand-md  fixed-top royalLogoMobile">
                <a className="navbar-brand" href="/">
                  <img src={OwnerlyLogo} alt='Ownerly Logo' className={commonStyles.HDImg} style={{ minHeight: '20px', minWidth: '80px' }}></img>
                </a>


                <button onClick={() => toggleNav( !showNav )} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>

                  {!showNav ?
                    <svg width="17" height="12" xmlns="http://www.w3.org/2000/svg">
                      <line x1="1" x2="16" y1="2" y2="2" stroke="#2a2727" strokeWidth="2" />

                      <line x1="1" x2="16" y1="6" y2="6" stroke="#2a2727" strokeWidth="2" />

                      <line x1="1" x2="16" y1="10" y2="10" stroke="#2a2727" strokeWidth="2" />
                    </svg>
                    :
                    <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg">
                      <line x1="1" x2="14" y1="1" y2="14" stroke="#2a2727" strokeWidth="2" />

                      <line x1="14" x2="1" y1="1" y2="14" stroke="#2a2727" strokeWidth="2" />
                    </svg>
                  }

                  {/* <img src={MenuImg} className={commonStyles.MenuImg}></img> */}
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="hide-sm" onMouseLeave={e => {
          e.persist(); maxY = Math.max( e.nativeEvent.pageY, maxY || 0 ); 
        }}>
          {active === 1 &&
                        <AboutContent />
          }

          {
            active === 2 &&
                        <HomePartners />
          }

          {
            active === 3 &&
                        <LifePartners />
          }
        </div>
      </div>


    </nav>


  );
};


export default Menu;


